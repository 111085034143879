<template>
  <div class="mt-6">
    <v-card
      :style="
        finished
          ? 'opacity: 1; background: ' + brandColor || '#ffffff'
          : 'opacity: 0; position: absolute; background: ' + brandColor ||
            '#ffffff'
      "
      class="pt-8 rounded-xl"
    >
      <div class="center-logo">
        <app-logo :logo="logo"></app-logo>
      </div>
      <v-toolbar
        class="font-weight-bold text-h5 mt-n5 d-flex justify-center"
        flat
        color="transparent"
      >
        Here's your recording
      </v-toolbar>
      <div :class="!final_step ? '' : 'showScreen'" class="video-frame">
        <div class="video-container">
          <video
            class="video-content video-js vjs-default-skin"
            id="screenVideo"
            ref="recordedVideo"
            playsinline
            controlsList="nodownload"
            oncontextmenu="return false"
          ></video>
        </div>
      </div>
      <v-card-actions class="pa-10 px-2">
        <v-spacer></v-spacer>
        <v-btn
          @click="redoRecording"
          class="secondary black--text capitalize rounded-xl custom-redo-btn"
          :large="$vuetify.breakpoint.mdAndUp"
          text
        >
          <v-icon>mdi-reload</v-icon>
          Redo
        </v-btn>
        <v-btn
          @click="finalStep"
          class="white--text capitalize rounded-xl ml-6 custom-final-step-btn"
          :large="$vuetify.breakpoint.mdAndUp"
          :color="accentColor || 'primary'"
        >
          Proceed to final step
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <final-step
      :settings="settings"
      :resolution="$parent.video_quality"
      v-show="final_step"
    ></final-step>
  </div>
</template>

<script>
import AppLogo from "./Logo";
import FinalStep from "./FinalStep";
import RecordRTC from "recordrtc";
import videojs from "video.js";
import "video.js/dist/video-js.css";
export default {
  components: {
    AppLogo,
    FinalStep,
  },
  props: {
    mic: {
      default: false,
      type: Boolean,
    },
    settings: {
      default: null,
      type: Object,
    },
  },
  name: "RecordScreen",
  data() {
    return {
      disabled: true,
      firstPage: true,
      startBtn: false,
      shareScreen: false,
      finished: false,
      final_step: false,
      showScreen: {
        opacity: 0,
        position: "absolute",
      },
      mediaOptions: {
        video: true,
      },
      video: null,
      camera: null,
      recorder: null,
      webCam: null,
      timer: null,
      time: null,
      firefox: navigator.userAgent.includes("Firefox"),
      streams: [],
      countdown_id: null,
      screenAndCam: false,
    };
  },
  computed: {
    brandColor() {
      return this.settings?.brand_color;
    },
    accentColor() {
      return this.settings?.accent_color;
    },
    logo() {
      return this.settings?.logo_url || null;
    },
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    this.video = document.getElementById("screenVideo");
    // eslint-disable-next-line no-unused-vars
    let audioTrack;

    const invokeGetDisplayMedia = (success, error) => {
      if ("mediaDevices" in navigator) {
        if (navigator.mediaDevices.getDisplayMedia) {
          if (this.$parent["toggleScreen"] || this.$parent["screenAndCam"]) {
            navigator.mediaDevices
              .getDisplayMedia(this.mediaOptions)
              .then(success)
              .catch(error);
          }
        }
        if (this.$parent["toggleCamera"] || this.$parent["screenAndCam"]) {
          navigator.mediaDevices
            .getUserMedia(this.mediaOptions)
            .then(success)
            .catch(error);
        }
      } else {
        alert("error");
      }
    };

    const stopRecordingCallback = () => {
      this.$parent.recordStarted = false;
      this.$parent.firstPage = false;
      this.$parent.shareScreen = true;
      this.$parent.disabled = false;
      this.$parent.record_btn = "start recording screen";
      this.video.muted = false;
      this.video.autoplay = false;
      this.video.controls = true;

      let videoPlayerSrc = (this.video.srcObject = null);
      // this.video.src = this.video.srcObject = null;
      if (this.$parent["toggleCamera"] || this.$parent["screenAndCam"]) {
        this.webCam.getTracks().forEach((track) => {
          track.stop();
        });
        if (document.pictureInPictureElement) {
          document.exitPictureInPicture();
        }
      }
      // this.video.src = URL.createObjectURL(this.recorder.getBlob());
      videoPlayerSrc = URL.createObjectURL(this.recorder.getBlob());

      const options = {
        controls: true,
        autoplay: false,
        preload: "auto",
        fluid: true,
        aspectRatio: "15:8",
        sources: [
          {
            src: videoPlayerSrc,
            type: "video/webm",
          },
        ],
      };
      // Create Video.js player
      videojs(this.$refs.recordedVideo, options);

      this.recorder.screen.stop();
      this.finished = true;
      clearInterval(this.timer);
    };

    const captureScreen = (callback) => {
      invokeGetDisplayMedia(
        (screen) => {
          addStreamStopListener(screen, () => {
            this.recorder.stopRecording(stopRecordingCallback);
          });
          callback(screen);
        },
        (error) => {
          console.error(error);
          window.location.reload();
          alert(error);
        }
      );
    };

    const recordCamera = async () => {
      if (this.$parent["toggleCamera"] || this.$parent["screenAndCam"]) {
        if (!this.$parent["toggleScreen"] && !this.$parent["screenAndCam"]) {
          this.$parent.recordStarted = true;
        }
        const handleSuccess = async (stream) => {
          this.camera = document.getElementById("webCamera");
          this.camera.srcObject = stream;
          this.webCam = stream;
          this.camera.autoplay = true;
          if (this.$parent["screenAndCam"]) {
            this.camera.onloadedmetadata = async () => {
              await this.camera.requestPictureInPicture();
            };
          }
        };
        let stream = await navigator.mediaDevices.getUserMedia({ video: true });
        await handleSuccess(stream);
      }
    };

    const recordAudio = async (screen) => {
      const audioStream = await navigator.mediaDevices
        .getUserMedia({ audio: true })
        .catch((e) => {
          throw e;
        });
      [audioTrack] = audioStream.getAudioTracks();
      screen.addTrack(audioTrack);
    };

    document.getElementById("captureBtn").addEventListener("click", () => {
      if (
        this.$parent["toggleScreen"] ||
        this.$parent["toggleCamera"] ||
        this.$parent["screenAndCam"]
      ) {
        if (this.finished) {
          this.finished = false;
        }
        if (this.recorder) {
          this.recorder = null;
        }
        this.video.style.opacity = "0";
        this.disabled = true;
        if (
          (this.$parent["toggleCamera"] || this.$parent["screenAndCam"]) &&
          !this.$parent["toggleScreen"]
        ) {
          recordCamera();
        }
        captureScreen(async (screen) => {
          this.$parent.startBtn = false;
          this.$parent.firstPage = true;
          this.$parent.shareScreen = false;
          this.$parent.disabled = true;
          this.$parent.record_btn = "Stop Recording";
          this.timer = setInterval(() => {
            this.time += 1;
          }, 1000);

          this.recorder = RecordRTC(screen, {
            type: "video",
            audioBitsPerSecond: 128 * 1000,
            videoBitsPerSecond: this.$parent.video_quality,
            mimeType: "video/webm;codecs=vp8",
            disableLogs: true,
          });
          this.video.muted = true;
          this.video.controls = false;
          this.video.srcObject = screen;

          if (this.mic) {
            if (
              (this.$parent["toggleCamera"] && !this.firefox) ||
              this.$parent["toggleScreen"] ||
              this.$parent["screenAndCam"]
            ) {
              await recordAudio(screen);
            }
          }

          const screens = [];
          screens.push(screen);
          if (this.$parent["screenAndCam"] && !this.screenAndCam) {
            screens.pop();
            this.screenAndCam = true;
          }
          if (window.navigator.platform.indexOf("Mac") > -1) {
            setTimeout(() => {
              if (screens[0]?.active === true) {
                this.$parent.countdown = true;
                this.countdown_id = setInterval(async () => {
                  if (this.$parent.countdown_timer <= 0) {
                    clearInterval(this.countdown_id);
                    this.recorder.startRecording();
                    this.video.autoplay = true;
                    this.video.style.opacity = "1";

                    this.recorder.screen = screen;
                  }
                }, 2000);
              }
            }, 1000);
          } else {
            if (screens[0]?.active === true) {
              this.$parent.countdown = true;
              this.countdown_id = setInterval(async () => {
                if (this.$parent.countdown_timer <= 0) {
                  clearInterval(this.countdown_id);
                  this.recorder.startRecording();
                  this.video.autoplay = true;
                  this.video.style.opacity = "1";

                  this.recorder.screen = screen;
                }
              }, 2000);
            }
          }
        });
      }
    });

    const addStreamStopListener = (stream, callback) => {
      stream.addEventListener(
        "ended",
        function () {
          callback();
          callback = function () {};
        },
        false
      );
      stream.addEventListener(
        "inactive",
        function () {
          callback();
          callback = function () {};
        },
        false
      );
      stream.getTracks().forEach((track) => {
        track.addEventListener(
          "ended",
          function () {
            callback();
            callback = function () {};
          },
          false
        );
        track.addEventListener(
          "inactive",
          function () {
            callback();
            callback = function () {};
          },
          false
        );
        if (
          this.$parent["screenAndCam"] &&
          this.$parent["firstPage"] === false
        ) {
          const timer = setTimeout(() => {
            track.stop();
            clearInterval(timer);
          }, 2000);
        }
      });
    };
    this.$root.$on("stop-recording", () => {
      this.recorder.stopRecording(stopRecordingCallback);
    });
  },
  methods: {
    redoRecording() {
      this.video.pause();
      window.location.reload();
    },
    finalStep() {
      this.finished = false;
      this.final_step = true;
      this.video.pause();
    },
  },
};
</script>

<style lang="scss" scoped>
.showScreen {
  opacity: 0;
  position: absolute;
  z-index: -999;
}
.video-frame {
  margin: 0px 40px !important;
}
.video-container {
  height: 390px !important;
  width: 100%;
  overflow: hidden;
  border: none !important;
  border-radius: 10px !important;
  background-color: transparent !important;
}
.video-content video {
  width: 100%;
  background-color: #222 !important;
  border-radius: 10px !important;
}
.custom-final-step-btn {
  width: 220px !important;
  border-radius: 50px !important;
}
.custom-redo-btn {
  width: 120px !important;
  border-radius: 50px !important;
}
</style>
